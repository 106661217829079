



































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import User from '@/models/User';
import Customer from '@/models/Customer';
import UserRole from '@/models/user-attributes/UserRole';
import {namespace} from 'vuex-class';
import Tenant from '@/models/Tenant';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import Location from '@/models/Location';
import Job from '@/models/Job';
import {tenantStoreGetter, tenantStoreMutations} from '@/stores/tenant.store';
import {jobStoreActions} from '@/stores/job.store';
import {userStoreGetter} from '@/stores/user.store';
import {customerStoreActions, customerStoreGetter} from '@/stores/customer.store';
import {locationStoreActions, locationStoreGetter} from '@/stores/location.store';
import moment from 'moment';


const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const TenantStore = namespace('tenant');
const JobStore = namespace('job');
const LocationStore = namespace('location');

const checkTime = (value: string) => {
  // The length of the time string should not be longer than 5(eg: 00:00) and contain not more than 2 separators
  if (value.length > 5) {
    return false;
  }
  const parts = value.split(':');
  if (parts.length === 2) {
    // The first part should not have more than 2 digits and needs to be greater than 0 and less than 23(24h format)
    if (!(parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23)) {
      return false;
    }
    // The second part should not have more than 2 digits and needs to be greater than 0 and less than 60(minutes)
    return parts[1].length <= 2 && +parts[1] >= 0 && +parts[1] < 60;
  }
  // If the string has no separator, it's the hours
  return parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23;
};

@Component({
  mixins: [validationMixin],
  validations: {
    startTime: {
      isTimeFormat: checkTime,
    },
  },
  components: {
    WorkingTimeComponent: () => import (
        '@/components/shared/WorkingTime.component.vue'),
    MenuWithPicker: () => import (
        '@/components/shared/MenuWithPicker.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    SideCard: () => import(
        '@/components/shared/SideCard.component.vue'),
    DatePickerComponent: () => import(
        /* webPackChunkName: "UserProfileComponent */
        '@/components/shared/DatePicker.component.vue'),
  },
})

export default class JobManageComponent extends mixins(ErrorMessageHandlerMixin) {
  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  public _tenant!: Tenant;
  @UserStore.Getter(userStoreGetter.ROLES)
  public _roles!: UserRole[];
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMER_ACTION)
  public loadCustomerAction!: (payload: { customerId: string, store: boolean }) => Promise<Customer>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @JobStore.Action(jobStoreActions.CREATE_JOB_ACTION)
  public createJobAction!: (job: Job) => Promise<Job>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATIONS_ACTION)
  private loadLocationsAction!: (payload: { tenantId: string, relations?: string[], store?: boolean }) => Promise<Location[]>;
  @JobStore.Action(jobStoreActions.UPDATE_JOB_ACTION)
  public updateJobAction!: (payload: { job: Job }) => Promise<Job>;
  @JobStore.Action(jobStoreActions.DELETE_JOB_ACTION)
  public deleteJobAction!: (jobId: string) => Promise<Job>;
  @JobStore.Action(jobStoreActions.LOAD_JOBS_ACTION)
  public loadJobsAction!: (locationId: string) => Promise<Job[]>;
  //endregion
  /**
   * Current TimeSchedule that being edited
   */
  public job: Job = new Job();

  @Prop({default: []})
  public jobs!: Job[];
  /**
   * Booleans to show the 'empty' text
   */
  public emptyLocation: boolean = false;
  /**
   * The customers to that can be chosen in the autocomplete
   */
  public customers: Customer[] = [];
  /**c
   * Selected location
   */
  public selectedLocation: Location = new Location();

  public startTime: string = '';
  /**
   * The Locations of the selected Customer
   */
  public locations: Location[] = [];

  /**
   * Possible Note Color
   */
  public colors = [
    this.$colorHandler.getThemeColor('task-default'),
    this.$colorHandler.getThemeColor('task-red'),
    this.$colorHandler.getThemeColor('task-yellow'),
    this.$colorHandler.getThemeColor('task-blue'),
    this.$colorHandler.getThemeColor('task-green')];

  /**
   * Prop to auto insert given information
   */
  @Prop({default: () => false})
  public autoInsert!: boolean;
  @Prop({default: false})
  public show!: boolean;


  @UserStore.Getter(userStoreGetter.USERS)
  private _users!: User[];
  @CustomerStore.Getter(customerStoreGetter.CUSTOMERS)
  private customersFromStore!: Customer[];
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMERS_ACTION)
  private loadCustomersAction!: (tenantId: string) => Promise<Customer[]>;
  @TenantStore.Mutation(tenantStoreMutations.STORE_TENANT)
  private storeTenant!: (tenant: Tenant) => any;
  /**
   * Current Selected Step
   * in the Stepper
   */

  private onEdit: boolean = false;
  private loaded: boolean = false;

  /**
   * is true, if the starttime of the location is in the past
   */
  private datePickerMin: string = '';


  public dateStartMinMax: { min: string, max: string } = {
    min: '',
    max: '',
  };
  /**
   * Min max values for date end picker
   */
  public dateEndMinMax: { min: string, max: string } = {
    min: '',
    max: '',
  };

  public get isOnLocation(): boolean {
    return !!this.$route.params.locationId;
  }
  /**
   * Array of all exceptions for use in the date picker
   */
  private allExceptions: string[] = [];
  //endregion
  /**
   * Value of time start picker
   */
  public timeStartPickerValue: string = '';
  /**
   * Value of time end picker
   */
  public timeEndPickerValue: string = '';
  /**
   * Main model of this component
   */

  public dateStartPickerValue: string = '';
  /**
   * Humanized date end string
   */
  public dateEndPickerValue: string = '';

  public hasSameTime: boolean = false;
  /**
   * Render Only obj with year as keys
   */
  private years: any = {};

  private splitExceptions() {
    const ts = this.job.timeSchedule;
    const workingDays = ts.weekdays;
    ts.daysOff = this.allExceptions.filter((exception) => workingDays.includes(moment(exception).day()));
    ts.additionalDays = this.allExceptions.filter((exception) => !ts.daysOff.includes(exception));
    this.genExceptionTable();
  }

  private genExceptionTable() {
    this.years = {};
    this.allExceptions.forEach((exception) => {
      const split = exception.split('-');
      const year = split[0];
      const month = parseInt(split[1])  - 1;
      const day = parseInt(split[2]);

      if (!this.years.hasOwnProperty(year)) {
        this.years[year] = [];
        for (let i = 0; i < 12; i++) {
          this.years[year].push({
            off: [],
            add: [],
          });
        }
      }
      if (this.job.timeSchedule.daysOff.includes(exception)) {
        this.years[year][month].off.push(day);
      } else {
        this.years[year][month].add.push(day);
      }
    });
  }

  public onDateEndChange(date: string) {
    this.checkForSameTime();
    this.dateStartMinMax.max = moment(date).toISOString(true);
    this.$emit('filter-changed');
  }

  public validateJob() {
    return !this.selectedLocation || !this.job.driverId || !this.startTime;
  }

  /**
   * Event handler for on start date change. Sets the date start display value and real cleanTime.Date value.
   */
  public onDateStartChange(date: string) {

    // Parse Date to moment object
    const parsedDate = moment(date);

    // Set End min Value
    this.dateEndMinMax.min = parsedDate.toISOString(true);
  }


  /**
   * Checks if the dates and the times are the same and shows an error message if so
   */
  public checkForSameTime() {
    if (this.timeStartPickerValue === '' || this.timeEndPickerValue === '' ||
        this.dateStartPickerValue === '' || this.dateEndPickerValue === '') {
      this.hasSameTime = false;
      return;
    }

    // set hasSameTime boolean according to if start and end have the same time
    this.hasSameTime = this.timeStartPickerValue === this.timeEndPickerValue
        && this.dateStartPickerValue === this.dateEndPickerValue;
  }

  public notExtendedTime: boolean = true;

  public extendTime() {
    this.notExtendedTime = !this.notExtendedTime;
  }

  public get allLocations(): Location[] {
    return this.locations;
  }

  public get availableUsers(): User[] {
    return this._users;
  }

  //region Store Getter, Action & Mutations
  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public _location!: Location;

  private get location() {
    return this._location;
  }

  public async initialise() {
    if (this.autoInsert) {
      if (this.location) {
        this.selectedLocation = this.location;
      }
    }
    if (!this.$route.params.locationId) {
      this.locations = await this.loadLocationsAction({tenantId: this.$route.params.tenantId, store: true});
    } else {
      this.locations.push(this.location);
    }
    this.setMinMax();
    this.allExceptions = this.job.timeSchedule.additionalDays.concat(this.job.timeSchedule.daysOff);
    this.allExceptions = this.allExceptions.map((exception) => exception.substring(0, 10));
    this.loaded = true;
  }

  public setMinMax() {
    if (moment(this.selectedLocation.startDate).isAfter(moment())) {
      this.dateStartMinMax.min = moment(this.selectedLocation.startDate).endOf('day').toISOString();
      this.datePickerMin = moment(this.selectedLocation.startDate).toISOString();
    } else {
      this.dateStartMinMax.min =  moment(this.selectedLocation.startDate).endOf('day').toISOString();
      this.datePickerMin = moment().toISOString();
    }
    this.dateEndMinMax.min = this.selectedLocation.startDate ?? '';
    this.dateStartMinMax.max = this.selectedLocation.endDate ?? '';
    this.dateEndMinMax.max = this.selectedLocation.endDate ?? '';
  }

  public getUserFullName(user: User): string {
    return user.firstName + ' ' + user.lastName;
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  public getLocationName(location: Location): string {
    return location.name!;
  }

  public getLocationId(location: Location): string {
    return location.id!;
  }


  /**
   * Resets the JobObject and its dialog text-fields
   */
  public resetJobDialog(): void {
    // Resets the current TimeSchedule object
    this.job = new Job();
    this.startTime = '';
    this.onEdit = false;

  }

  /**
   * Sends Event to Close the Modal, resets all Input
   */
  public cancelButtonClicked(): void {
    this.resetJobDialog();
    this.$emit('exitModal', null);
  }

  public resetTimeScheduleDays() {
    this.job.timeSchedule.daysOff = [];
    this.job.timeSchedule.startDate = '';
    this.job.timeSchedule.endDate = '';
  }


  /**
   * Called when timeSchedule should be edited, inserts the timeSchedule to the form
   * @param job
   * @param editMode
   */
  public setEditJob(job: Job, editMode: boolean = true): void {
    this.resetJobDialog();
    // Sets the Edit mode to true
    this.onEdit = editMode;
    // Set passed Job as current
    if (job.id) {
      this.job = Job.parseFromObject(job);
      this.startTime = this.getJobTime(this.job);
    }
  }

  public getJobTime(item: Job) {
    return item.timeSchedule.startTime?.hour.toString().padStart(2, '0')
        + ':' + item.timeSchedule.startTime?.minute.toString().padStart(2, '0');
  }

  /**
   * Submit method (user presses submit button)
   */
  private async onSubmit(): Promise<void> {
    this.splitExceptions();
    // Tries to execute the api request
    try {

      // delete attributes useless for managing Jobs
      delete this.job.location;
      this.job.locationId = this.selectedLocation.id!;

      // when there are Weekdays with no work
      if (this.job.timeSchedule.weekdays.length > 0 && this.job.timeSchedule.weekdays.length <= 7) {
        // filter redundant days, where the day is free by default
        this.job.timeSchedule.daysOff = this.job.timeSchedule.daysOff
            .filter((day) => this.job.timeSchedule.weekdays
                .map((weekday) => weekday).includes(moment(day).day()));
      }

      // reformat startTime to object
      this.job.timeSchedule.startTime = {
        hour: moment(this.startTime, 'HH:mm').hours(),
        minute: moment(this.startTime, 'HH:mm').minutes(),
      };

      // if startDate and endDate are empty, assign the whole timeframe
      if (!this.job.timeSchedule.startDate) {
        if (moment().isAfter(moment(this.selectedLocation.startDate!))) {
          this.job.timeSchedule.startDate = moment().toISOString();
        } else {
          this.job.timeSchedule.startDate = this.selectedLocation.startDate!;
        }
      }

      if (!this.job.timeSchedule.endDate) {
        this.job.timeSchedule.endDate = this.selectedLocation.endDate!;
      }

      // adjust start and end-time to be at the earliest and latest time possible
      if (moment().format('L') === moment( this.job.timeSchedule.startDate).format('L')) {
        this.job.timeSchedule.startDate =  moment(this.job.timeSchedule.startDate).set('hour', moment().hour()).toISOString(false);
      }
      this.job.timeSchedule.endDate = moment( this.job.timeSchedule.endDate).endOf('day').subtract(2, 'second').toISOString(false);

      // timeSchedule should be edited
      if (this.onEdit) {
        delete this.job.driver;
        delete this.job.tenant;
        delete this.job.workSessions;
        await this.editJob();
      } else {
        // create new Job
        await this.createJob();
      }

      // Send Close Modal to parent component
      this.$emit('exitModal', null);

      // resets the stepper


    } catch (e) {
      if (this.$te(`USER_MANAGE.NOTIFICATIONS.${e.status}`)) {
        // shows an error notification depending on the status of the error
        this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.${e.status}`);
      } else {
        // shows the general error messages for creating timeSchedules
        this.$notifyErrorSimplified('JOB_COMPONENT.NOTIFICATIONS.ERROR.CREATE_JOB');
      }
    }
  }

  /**
   * edits a certain, existing timeSchedule by the inputs the user set inside the form
   * the timeSchedule data is updated by sending an event to the customer
   * dashboard which handles the update
   */
  private async editJob(): Promise<void> {
    try {
      await this.updateJobAction({job: this.job});

      // Shows success notification
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_JOB.SUCCESS');

      // Update Job by sending an Event to the Customer Dashboard
      this.$emit('updatedJob', this.job);
    } catch (e) {
      // Shows error notification
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_JOB.ERROR');
    }
  }

  /**
   * sends create request to the api,
   * creates the timeSchedule
   */
  private async createJob(): Promise<void> {


    // Sends the Job object to the API to create an instance in the backend
    const job = await this.createJobAction(this.job);

    // Notify with a success message,
    this.$notifySuccessSimplified('JOB_COMPONENT.NOTIFICATIONS.CREATE_JOB');

    // send created timeSchedule event to parent component
    this.$emit('createdJob', job);
  }

  @Watch('show')
  private watchJobManage() {
    if (this.show) {
      this.initialise();
    }
  }

  @Watch('location')
  private watchLocation(value: Location) {
    this.emptyLocation = false;
    this.selectedLocation = value;
  }

  public closeMenu(refName: 'location' | 'driver') {
    // @ts-ignore
    this.$refs[refName].$refs.myAutocomplete.$refs.menu.save();
  }

  private get filteredJobs(): Job[] {
    if (!this.startTime) {
      return [];
    }
    let jobs = [...this.jobs];
    if (this.job.id) {
      jobs = jobs.filter((job) => job.id !== this.job.id);
    }
    if (this.dateEndMinMax.max !== this.selectedLocation.endDate ||
        this.dateStartMinMax.min !== this.selectedLocation.startDate) {
      jobs.filter((job) => this.jobOverlaps(job, this.dateStartMinMax.min, this.dateEndMinMax.max));
    }
    const time = this.startTime.split(':');
    const start = {hour: parseInt(time[0]), minute: parseInt(time[1])};
    jobs = jobs.filter((job) => JSON.stringify(job.timeSchedule.startTime)
        === JSON.stringify(start));

    return jobs;
  }

  private jobOverlaps(job: Job, start: string, end: string): boolean {
    const startA = moment(job.timeSchedule.startDate);
    const startB = moment(start);
    const endA = moment(job.timeSchedule.endDate);
    const endB = moment(end);

    return !(endA.isBefore(startB) || endB.isBefore(startA));
  }
}
